import Vue from "vue";
import VueRouter from "vue-router";
// import NotFound from "../views/EmptyStates.vue"

Vue.use(VueRouter);

const url = process.env.VUE_APP_API_URL;
const axios = require("axios");

const routes = [
  {
    path: "/suporte",
    name: "TicketSupport",
    component: () => import("../components/Chat/TicketSupport.vue"),
    beforeEnter: async (to, from, next) => {
      const token = "Bearer " + localStorage.getItem("accessToken");
      const isAuthenticated = !!localStorage.getItem("accessToken");
      let role;
      if (isAuthenticated) {
        try {
          let res = await axios.get(`${url}/checkRole`, {
            headers: {
              authorization: token,
            },
          });
          role = res.data.role;
        } catch (error) {
          next({
            name: "LoginAdmin",
          });
        }
      }
      if (!isAuthenticated || role !== "admin") {
        next({
          name: "LoginAdmin",
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Homes/Home.vue"),
  },
  {
    path: "/faq",
    name: "Frequently Asked Questions",
    component: () => import(/* webpackChunkName: "about" */ "../views/FAQ.vue"),
  },
  {
    path: "/privacypolicy",
    name: "Privacy Policy",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PrivacyPolicy.vue"),
  },
  {
    path: "/termsofuse",
    name: "Terms of Use",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TermsUse.vue"),
  },
  {
    path: "/software",
    name: "Software",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Download.vue"),
  },
  {
    path: "/forgetPassword",
    name: "Forget Passwords",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ForgetPassword.vue"),
  },
  {
    path: "/responsibleRecord",
    name: "Responsible Record",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ResponsibleRecord.vue"),
  },  
  {
    path: "/login",
    name: "Login",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/login/pedagogico",
    name: "LoginPedagogico",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginPedagogico.vue"),
  },
  {
    path: "/login/admin",
    name: "LoginAdmin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginAdmin.vue"),
  },
  {
    path: "/student",
    name: "Student",
    children: [
      {
        path: "Forum"
      },
      {
        path: "Atividades"
      },
      {
        path: "Progresso",
        children: [
          {
            path: "Detalhas"
          }
        ],
      },
      {
        path: "Cursos",
        children: [
          {
            path: "AulasCurso"
          }
        ],
      },
      {
        path: "MaterialExt"
      },
      {
        path: "Events"
      },
      {
        path: "Perfil",
        children: [
          {
            path: "PerfilDetalhes"
          },
          {
            path: "Friends"
          },
          {
            path: "Edit"
          }
        ],
      }
    ],
    component: () => import("../views/Student.vue"),
    beforeEnter: async (to, from, next) => {
      const token = "Baerer " + localStorage.getItem("accessToken");
      const isAuthenticated = !!localStorage.getItem("accessToken");
      let role;
      if (isAuthenticated) {
        try {
          let res = await axios.get(`${url}/checkRole`, {
            headers: {
              authorization: token,
            },
          });
          role = res.data.role;
        } catch (error) {
          next({
            name: "Login",
          });
        }
      }
      if (!isAuthenticated || role !== "student") {
        next({
          name: "Login",
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/parent",
    name: "Parent",
    component: () => import("../views/Parent.vue"),
    beforeEnter: async (to, from, next) => {
      const token = "Baerer " + localStorage.getItem("accessToken");
      const isAuthenticated = !!localStorage.getItem("accessToken");
      let role;
      if (isAuthenticated) {
        try {
          let res = await axios.get(`${url}/checkRole`, {
            headers: {
              authorization: token,
            },
          });
          role = res.data.role;
        } catch (error) {
          next({
            name: "Login",
          });
        }
      }
      if (!isAuthenticated || role !== "student") {
        next({
          name: "Login",
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Admin.vue"),
    beforeEnter: async (to, from, next) => {
      const token = "Baerer " + localStorage.getItem("accessToken");
      const isAuthenticated = !!localStorage.getItem("accessToken");
      let role;
      if (isAuthenticated) {
        try {
          let res = await axios.get(`${url}/checkRole`, {
            headers: {
              authorization: token,
            },
          });
          role = res.data.role;
        } catch (error) {
          next({
            name: "LoginAdmin",
          });
        }
      }
      if (!isAuthenticated || role !== "admin") {
        next({
          name: "LoginAdmin",
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/school",
    name: "School",
    component: () => import("../views/SchoolView.vue"),
    beforeEnter: async (to, from, next) => {
      const token = "Baerer " + localStorage.getItem("accessToken");
      const isAuthenticated = !!localStorage.getItem("accessToken");
      let role;
      if (isAuthenticated) {
        try {
          let res = await axios.get(`${url}/checkRole`, {
            headers: {
              authorization: token,
            },
          });
          role = res.data.role;
        } catch (error) {
          next({
            name: "LoginPedagogico",
          });
        }
      }
      if (!isAuthenticated || role !== "school") {
        next({
          name: "LoginPedagogico",
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/teacher",
    name: "Teacher",
    children: [
      {
        path: "Training",
        children: [
          {
            path: "Files"
          }
        ],
      },
      {
        path: "Role"
      },
      {
        path: "Class"
      },
      {
        path: "Events"
      },
      {
        path: "Student"
      },
      {
        path: "Certificate"
      }
    ],
    component: () => import("../views/HomeTeacher.vue"),
    beforeEnter: async (to, from, next) => {
      const token = "Baerer " + localStorage.getItem("accessToken");
      const isAuthenticated = !!localStorage.getItem("accessToken");
      let role;
      if (isAuthenticated) {
        try {
          let res = await axios.get(`${url}/checkRole`, {
            headers: {
              authorization: token,
            },
          });
          role = res.data.role;
        } catch (error) {
          next({
            name: "LoginPedagogico",
          });
        }
      }
      if (!isAuthenticated || role !== "teacher") {
        next({
          name: "LoginPedagogico",
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/teacherFuture",
    name: "Teacher",
    children: [
      {
        path: "Training",
        children: [
          {
            path: "Files"
          }
        ],
      },
      {
        path: "Role"
      },
      {
        path: "Class"
      },
      {
        path: "ClassInfo"
      },
      {
        path: "ClassResume"
      },
      {
        path: "Events"
      },
      {
        path: "Student"
      },
      {
        path: "Certificate"
      }
    ],
    component: () => import("../views/HomeTeacherFuture.vue"),
    beforeEnter: async (to, from, next) => {
      const token = "Baerer " + localStorage.getItem("accessToken");
      const isAuthenticated = !!localStorage.getItem("accessToken");
      let role;
      if (isAuthenticated) {
        try {
          let res = await axios.get(`${url}/checkRole`, {
            headers: {
              authorization: token,
            },
          });
          role = res.data.role;
        } catch (error) {
          next({
            name: "LoginPedagogico",
          });
        }
      }
      if (!isAuthenticated || role !== "teacher") {
        next({
          name: "LoginPedagogico",
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/administrator",
    name: "Client",
    component: () => import("../views/ClientView.vue"),
    beforeEnter: async (to, from, next) => {
      const token = "Baerer " + localStorage.getItem("accessToken");
      const isAuthenticated = !!localStorage.getItem("accessToken");
      let role;
      if (isAuthenticated) {
        try {
          let res = await axios.get(`${url}/checkRole`, {
            headers: {
              authorization: token,
            },
          });
          role = res.data.role;
        } catch (error) {
          next({
            name: "LoginPedagogico",
          });
        }
      }
      if (!isAuthenticated || role !== "client") {
        next({
          name: "LoginPedagogico",
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/manager",
    name: "Manager",
    component: () => import("../views/ManagerView.vue"),
    beforeEnter: async (to, from, next) => {
      const token = "Baerer " + localStorage.getItem("accessToken");
      const isAuthenticated = !!localStorage.getItem("accessToken");
      let role;
      if (isAuthenticated) {
        try {
          let res = await axios.get(`${url}/checkRole`, {
            headers: {
              authorization: token,
            },
          });
          role = res.data.role;
        } catch (error) {
          next({
            name: "LoginPedagogico",
          });
        }
      }
      if (!isAuthenticated || role !== "manager") {
        next({
          name: "LoginPedagogico",
        });
      } else {
        next();
      }
    },
  },
  {
    path: "*",
    name: "EmptyStates",
    component: () => import("../views/EmptyStates.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
